import React from "react";
import { FormattedHTMLMessage, useIntl } from "gatsby-plugin-intl";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { css, Global } from "@emotion/core";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Container from "../components/Container";
import Rice from "../components/Rice";
import Jasmine from "../components/Jasmine";
import {
  Mobile,
  Desktop,
  MinTabletWidth,
  Tablet,
} from "../constants/responsive";
import BlogList from "../components/BlogList";

import Media from "../components/Media";

import Elevator from "../svgs/icons/elevator.svg";
import Journey from "../svgs/icons/journey.svg";
import CustomSoftware from "../svgs/icons/custom-software.svg";

import Asratec from "../svgs/partners/asratec.svg";

import Pandemic from "../svgs/awards/pandemic-color.svg";
import ICT from "../svgs/awards/ict-color.svg";

import Distance from "../svgs/kpi/distance.svg";
import Mission from "../svgs/kpi/mission.svg";
import Uptime from "../svgs/kpi/uptime.svg";
import Portal from "../components/Portal";
import VideoCarousel from "../components/VideoCarousel";

import RiceBlinkingEyes from "../images/rice/rice-blinking-eyes.gif";
import JasmineBlinkingEyes from "../images/jasmine/jasmine-blinking-eyes.gif";
import PortalBlinkingEyes from "../svgs/eyes/portal-blinking-eyes.svg";
import RobotImage from "../components/RobotImage";

import FeatureCard from "../components/FeatureCard";

import { AwardBoxHoverLayer, AwardImageBox } from "../components/AwardBox";
import FadeInSection from "../components/FadeInSection";
import RobotSection from "../components/RobotSection";
import Slogan from "../components/Slogan";
import { blinkingEyeStyle } from "../constants/styles";
import RiceFeatures from "../components/RiceFeatures";
import JasmineFeatures from "../components/JasmineFeatures";
import PortalFeatures from "../components/PortalFeatures";

const IndexPage = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      riceHero: file(relativePath: { eq: "rice/rice.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 85) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      jasmineHero: file(relativePath: { eq: "jasmine/jasmine.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 85) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portalHero: file(relativePath: { eq: "portal/portal.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 85) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      rice: file(relativePath: { eq: "rice/rice-front.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 85) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      jasmine: file(relativePath: { eq: "jasmine/jasmine-front.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 85) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      portal: file(relativePath: { eq: "portal/portal-front.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 85) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      dashboard: file(relativePath: { eq: "dashboard.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      jumpstarter: file(relativePath: { eq: "awards/jumpstarter.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      jumpstarterMobile: file(
        relativePath: { eq: "awards/jumpstarter-mobile.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      socialImpactAward: file(
        relativePath: { eq: "awards/social-impact-award.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      socialImpactAwardMobile: file(
        relativePath: { eq: "awards/social-impact-award-mobile.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      forbes: file(relativePath: { eq: "awards/forbes.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      cyberport: file(relativePath: { eq: "thumbnails/cyberport.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      dorsett: file(relativePath: { eq: "thumbnails/dorsett.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      handerson: file(relativePath: { eq: "thumbnails/handerson.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      hactl: file(relativePath: { eq: "thumbnails/hactl.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      hkcec2: file(relativePath: { eq: "thumbnails/hkcec2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      hkcec1: file(relativePath: { eq: "thumbnails/hkcec1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      japanPost: file(relativePath: { eq: "thumbnails/japanPost.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      jrEast: file(relativePath: { eq: "thumbnails/jrEast.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      k11Musea: file(relativePath: { eq: "thumbnails/k11Musea.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      k11Atelier: file(relativePath: { eq: "thumbnails/k11Atelier.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      leMeridien: file(relativePath: { eq: "thumbnails/leMeridien.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      lHotel: file(relativePath: { eq: "thumbnails/lHotel.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      moko: file(relativePath: { eq: "thumbnails/moko.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      otis: file(relativePath: { eq: "thumbnails/otis.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sino: file(relativePath: { eq: "thumbnails/sino.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      skycity: file(relativePath: { eq: "thumbnails/skycity.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      softbank1: file(relativePath: { eq: "thumbnails/softbank1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      softbank2: file(relativePath: { eq: "thumbnails/softbank2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      softbank3: file(relativePath: { eq: "thumbnails/softbank3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      tmtPlaza: file(relativePath: { eq: "thumbnails/tmtPlaza.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mitsui1: file(relativePath: { eq: "thumbnails/mitsui1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      mitsui2: file(relativePath: { eq: "thumbnails/mitsui2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      taiseiCorporation: file(
        relativePath: { eq: "thumbnails/taiseiCorporation.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      tokyoMidtown: file(relativePath: { eq: "thumbnails/tokyoMidtown.png" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      japan711: file(relativePath: { eq: "thumbnails/japan711.png" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      nhkNews: file(relativePath: { eq: "thumbnails/nhkNews.png" }) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <Global />

      <Layout>
        <SEO
          title={intl.formatMessage({ id: "title" })}
          description={intl.formatMessage({ id: "description" })}
        />
        <Container>
          <div
            css={css`
              height: 100%;
              ${Tablet} {
                height: 650px;
              }
              ${Desktop} {
                height: 910px;
              }
            `}
          >
            <Slogan />

            <div
              css={css`
                position: relative;
                z-index: 5;
                bottom: 140px;
                display: flex;
                justify-content: space-around;
                align-items: flex-end;
                ${Tablet} {
                  bottom: 150px;
                }
                ${Desktop} {
                  bottom: 210px;
                  margin-top: 20px;
                  gap: 15px;
                }
              `}
            >
              <RobotImage
                fadeInTime={400}
                fluid={data.riceHero.childImageSharp.fluid}
                eyeStyle={css`
                  width: 39%;
                  top: 28.5%;
                  left: 28%;

                  ${Tablet} {
                    width: 93px;
                    top: 94px;
                    left: 72px;
                  }
                  ${Desktop} {
                    width: 146px;
                    top: 143px;
                    left: 108px;
                  }
                `}
                popUpTextPosition={{
                  [Mobile]: {
                    bottom: "-35px",
                    right: "50%",
                    transform: "translate(50%,50%)",
                  },

                  [Tablet]: {
                    bottom: "-16%",
                    right: "43%",
                  },

                  [Desktop]: {
                    bottom: "34.5%",
                    right: "47.5%",
                  },
                }}
                robotName="rice"
                imageStyle={css`
                  right: 20px;

                  ${Tablet} {
                    right: 25px;
                    width: 250px;
                  }
                  ${Desktop} {
                    width: 400px;
                    bottom: 24px;
                    &:hover {
                      transform: translate(-3%, 1.5%) scale(1.02);
                    }
                  }
                `}
                blinkingEyeImage={
                  <img
                    src={RiceBlinkingEyes}
                    alt="rice-blinking-eyes"
                    className="blinking-eyes"
                    css={blinkingEyeStyle}
                  />
                }
              />

              <RobotImage
                fadeInTime={600}
                fluid={data.jasmineHero.childImageSharp.fluid}
                eyeStyle={css`
                  width: 39%;
                  top: 32%;
                  left: 29.5%;
                  ${Tablet} {
                    width: 97px;
                    top: 108px;
                    left: 74px;
                  }
                  ${Desktop} {
                    width: 150px;
                    top: 169px;
                    left: 115px;
                  }
                `}
                robotName="jasmine"
                popUpTextPosition={{
                  [Mobile]: {
                    bottom: "-39px",
                    right: "51.5%",
                    transform: "translate(50%,50%)",
                  },

                  [Tablet]: {
                    bottom: "-17%",
                    right: "38.5%",
                  },
                  [Desktop]: {
                    bottom: "32%",
                    right: "40.5%",
                  },
                }}
                imageStyle={css`
                  right: -3px;
                  bottom: 4px;
                  ${Tablet} {
                    right: 5px;

                    width: 255px;
                  }
                  ${Desktop} {
                    right: 4px;
                    width: 410px;
                    bottom: 30px;

                    &:hover {
                      transform: translate(0, 1.5%) scale(1.02);
                    }
                  }
                `}
                blinkingEyeImage={
                  <img
                    src={JasmineBlinkingEyes}
                    alt="jasmine-blinking-eyes"
                    className="blinking-eyes"
                    css={blinkingEyeStyle}
                  />
                }
              />

              <RobotImage
                fadeInTime={800}
                fluid={data.portalHero.childImageSharp.fluid}
                eyeStyle={css`
                  background: #fff;
                  width: 44%;
                  left: 30.5%;
                  top: 4%;

                  ${Tablet} {
                    width: 97px;
                    top: 20px;
                    left: 71px;
                    height: auto;
                  }
                  ${Desktop} {
                    width: 160px;
                    top: 31px;
                    left: 107px;
                    height: auto;
                  }
                `}
                robotName="portal"
                popUpTextPosition={{
                  [Mobile]: {
                    bottom: "-45.25px",
                    left: "49%",
                    transform: "translateX(-50%)",
                  },

                  [Tablet]: {
                    bottom: "-9.3%",
                    left: "36%",
                  },

                  [Desktop]: {
                    bottom: "50%",
                    left: "41%",
                  },
                }}
                imageStyle={css`
                  top: 8.75px;
                  left: 20px;

                  ${Tablet} {
                    top: 8px;
                    width: 230px;
                  }
                  ${Desktop} {
                    width: 380px;
                    top: -10px;

                    &:hover {
                      transform: translate(3%, 1.5%) scale(1.02);
                    }
                  }
                `}
                blinkingEyeImage={
                  <PortalBlinkingEyes
                    className="blinking-eyes"
                    css={blinkingEyeStyle}
                  />
                }
              />
            </div>
          </div>
        </Container>

        <div
          css={css`
            background-color: #212121;
            width: 100%;
            display: flex;
            justify-content: center;
            padding-top: 60px;
            padding-bottom: 70px;

            ${Tablet} {
              padding-top: 70px;

              padding-bottom: 80px;
            }
            ${Desktop} {
              padding-top: 100px;

              padding-bottom: 70px;
            }
          `}
        >
          <Container>
            <div
              id="about"
              css={css`
                color: #fff;
                padding-top: 30px;
                ${Tablet} {
                  padding-top: 50px;
                }
              `}
            >
              <div
                css={css`
                  margin: 0 auto;
                  max-width: 525px;
                  ${Tablet || Desktop} {
                    max-width: 1050px;
                  }
                `}
              >
                <h2
                  css={css`
                    text-align: center;
                    line-height: 2;
                    margin-bottom: 0;
                    width: 100%;
                    color: #fff;
                  `}
                >
                  <span
                    css={css`
                      font-weight: bold;
                    `}
                  >
                    <FormattedHTMLMessage id="companyName" />
                  </span>
                  &nbsp;
                  <FormattedHTMLMessage id="about" />
                </h2>
              </div>

              <div
                css={css`
                  margin-top: 60px;
                  ${Desktop} {
                    margin-top: 100px;
                  }
                `}
              >
                <VideoCarousel data={data} />
              </div>

              <h2
                css={css`
                  text-align: center;
                  width: 100%;
                  font-weight: bold;
                  margin-bottom: 40px;
                  margin-top: 60px;
                  font-size: 24px;
                  ${Desktop} {
                    font-size: 36px;
                    margin-bottom: 50px;

                    margin-top: 100px;
                  }
                `}
              >
                <FormattedHTMLMessage id="kpi.journey" />
              </h2>

              <div
                css={css`
                  display: flex;
                  flex-wrap: wrap;
                  flex-direction: column;
                  justify-content: center;

                  ${Tablet} {
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: 20px;
                  }
                  ${Desktop} {
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: 30px;
                  }
                `}
              >
                {[
                  {
                    image: <Distance />,
                    number: "6K+",
                    text: <FormattedHTMLMessage id="kpi.distance" />,
                  },
                  {
                    image: <Mission />,
                    number: "45K+",
                    text: <FormattedHTMLMessage id="kpi.mission" />,
                  },
                  {
                    image: <Uptime />,
                    number: "150K+",
                    text: <FormattedHTMLMessage id="kpi.uptime" />,
                  },
                ].map(({ image, number, text }) => {
                  return (
                    <div
                      key={number}
                      css={css`
                        display: flex;
                        align-items: center;
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 40px;
                        width: 200px;
                        ${Tablet} {
                          margin: 0;
                          width: unset;
                        }
                        ${Desktop} {
                          margin: 0;
                          width: unset;
                          align-items: space-between;
                        }
                      `}
                    >
                      <div
                        css={css`
                          margin-right: 30px;
                          svg {
                            height: 60px;
                            width: 60px;
                            fill: #fff;
                          }
                          ${Tablet} {
                            margin-right: 30px;
                            svg {
                              height: 80px;
                              width: 80px;

                              fill: #fff;
                            }
                          }
                          ${Desktop} {
                            margin-right: 40px;
                            svg {
                              height: 100px;
                              width: 100px;
                              fill: #fff;
                            }
                          }
                        `}
                      >
                        {image}
                      </div>
                      <div
                        css={css`
                          display: flex;
                          flex-direction: column;
                          ${Tablet} {
                            width: 80px;
                          }

                          ${Desktop} {
                            width: 200px;
                          }
                        `}
                      >
                        <h1>{number}</h1>
                        <h3
                          css={css`
                            margin-bottom: 0px;
                            line-height: 1.25;
                          `}
                        >
                          <b>{text}</b>
                        </h3>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                css={css`
                  font-size: 14px;
                  text-align: center;
                  font-weight: bold;
                  color: #616161;
                `}
              >
                <FormattedHTMLMessage id="kpi.asOf" />
              </div>
              <div
                css={css`
                  width: 100%;
                  max-width: 450px;
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  margin: 0 auto;
                  padding-top: 60px;
                  padding-bottom: 20px;
                  ${Tablet} {
                    max-width: 100%;

                    padding-top: 60px;
                    padding-bottom: 0px;
                  }
                  ${Desktop} {
                    padding-top: 100px;
                    padding-bottom: 0px;
                  }
                `}
              >
                <h2
                  css={css`
                    color: #fff;
                    text-align: center;
                    margin-bottom: 40px;
                    width: 100%;
                    font-weight: bold;
                    font-size: 24px;
                    ${Desktop} {
                      font-size: 36px;
                      margin-bottom: 50px;
                    }
                  `}
                >
                  <div id="awards" />
                  <FormattedHTMLMessage id="awards" />
                </h2>

                <div
                  css={css`
                    display: grid;
                    width: 100%;
                    grid-template-columns: auto;
                    grid-template-rows: repeat(5, auto);
                    ${Tablet} {
                      grid-template-columns: repeat(2, 50%);
                      grid-template-rows: repeat(3, auto);
                    }
                    ${Desktop} {
                      grid-template-columns: repeat(4, 290px);
                      grid-template-rows: repeat(2, 290px);
                    }
                  `}
                >
                  <div
                    css={css`
                      position: relative;
                      aspect-ratio: 1.75/1;
                      ${Tablet} {
                        grid-column: 1/3;
                        aspect-ratio: 2/1;
                      }
                      ${Desktop} {
                        aspect-ratio: 1/1;

                        grid-column: 1/3;
                        grid-row: 1/3;
                      }
                    `}
                  >
                    <a
                      href="https://2021.jumpstarter.hk/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AwardBoxHoverLayer
                        award={
                          <>
                            JUMPSTARTER
                            <br />
                            Winner <br />
                          </>
                        }
                        year="2021"
                      />

                      <Img
                        fluid={[
                          data.jumpstarterMobile.childImageSharp.fluid,
                          {
                            ...data.jumpstarter.childImageSharp.fluid,
                            media: MinTabletWidth,
                          },
                        ]}
                        loading="eager"
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                        fadeIn={false}
                      />
                    </a>
                  </div>

                  <AwardImageBox
                    link="http://hkcs.org.hk/pidsa/2020/2020_assets/20201218_PIDSA2020_Result-Announcement_Eng.pdf"
                    award="Outstanding Productivity Award"
                    year="2020"
                  >
                    <Pandemic
                      css={css`
                        width: 80%;
                      `}
                    />
                  </AwardImageBox>

                  <AwardImageBox
                    link="https://www.forbes.com/sites/forbesasiateam/2021/08/09/forbes-asia-100-to-watch/?sh=6795d7993752"
                    award={
                      <>
                        Forbes Asia
                        <br />
                        100 To Watch
                      </>
                    }
                    year="2021"
                    fluid={data.forbes.childImageSharp.fluid}
                  />

                  <AwardImageBox
                    link="https://2021.jumpstarter.hk/"
                    award={
                      <>
                        JUMPSTARTER
                        <br />
                        Social Impact Award <br />
                      </>
                    }
                    year="2021"
                    fluid={[
                      data.socialImpactAwardMobile.childImageSharp.fluid,
                      {
                        ...data.socialImpactAward.childImageSharp.fluid,
                        media: MinTabletWidth,
                      },
                    ]}
                  />

                  <AwardImageBox
                    link="https://www.ictstartup.org/winner-list"
                    award={
                      <>
                        ICT Startup
                        <br />
                        (Hardware and Devices)
                        <br />
                        Silver Award
                      </>
                    }
                    year="2020"
                  >
                    <ICT
                      css={css`
                        width: 100%;
                      `}
                    />
                  </AwardImageBox>
                </div>
              </div>

              <div
                css={css`
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  flex-direction: column;

                  padding-top: 40px;
                  padding-bottom: 20px;
                  ${Tablet} {
                    padding: 60px 0px 40px 0px;
                  }
                  ${Desktop} {
                    padding: 100px 0px 80px 0px;
                  }
                `}
              >
                <h2
                  css={css`
                    color: #fff;
                    text-align: center;
                    width: 100%;
                    font-weight: bold;
                    margin-bottom: 40px;
                    font-size: 24px;
                    ${Tablet} {
                    }
                    ${Desktop} {
                      font-size: 36px;

                      margin-bottom: 50px;
                    }
                  `}
                >
                  <FormattedHTMLMessage id="partners" />
                </h2>

                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    flex-wrap: wrap;
                    svg,
                    path {
                      fill: #d4d4d4;
                    }
                    margin-top: -20px;
                    ${Tablet} {
                      margin-top: 0px;
                    }
                  `}
                >
                  <Media
                    href="https://www.asratec.co.jp/products/rice/"
                    width={[120, 150, 150]}
                  >
                    <Asratec />
                  </Media>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div
          css={css`
            position: relative;
            z-index: 3;
            margin-bottom: 90px;
            ${Tablet} {
              margin-bottom: 120px;
            }
            ${Desktop} {
              margin-bottom: 150px;
            }
          `}
        >
          <Container>
            <div // Card Container
              css={css`
                display: flex;
                margin-top: 90px;
                flex-direction: column;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                max-width: 100%;

                ${Tablet} {
                  max-width: 100%;
                  margin-top: 120px;
                  align-items: flex-start;
                  justify-content: space-between;
                }
                ${Desktop} {
                  max-width: 100%;
                  justify-content: center;
                  margin: 150px auto 0 auto;
                }
              `}
            >
              <h1
                css={css`
                  color: #000;
                  text-align: center;
                  width: 100%;
                  font-weight: bold;
                  margin-bottom: 30px;
                  ${Tablet} {
                    margin-bottom: 40px;
                  }
                  ${Desktop} {
                    margin-bottom: 60px;
                  }
                `}
              >
                <div id="features" />
                <FormattedHTMLMessage id="features" />
              </h1>

              <FadeInSection>
                <div
                  css={css`
                    display: grid;
                    grid-template-columns: auto;
                    grid-auto-rows: 1fr;
                    row-gap: 20px;
                    ${Tablet} {
                      row-gap: 25px;
                    }

                    ${Desktop} {
                      display: flex;
                      justify-content: space-between;
                      column-gap: 60px;
                      row-gap: 0px;
                    }
                  `}
                >
                  <FeatureCard
                    icon={
                      <Elevator
                        css={css`
                          margin-left: 0px;
                          height: 77px;
                          margin-bottom: 8px;

                          ${Tablet} {
                            height: 90px;
                            margin-bottom: -5px;
                          }

                          ${Desktop} {
                            margin-bottom: -10px;
                            height: 75px;
                          }
                        `}
                      />
                    }
                    title={<FormattedHTMLMessage id="elevator.title" />}
                    text={<FormattedHTMLMessage id="elevator.text" />}
                  />
                  <FeatureCard
                    icon={
                      <Journey
                        css={css`
                          height: 65px;

                          ${Tablet} {
                            height: 80px;
                          }

                          ${Desktop} {
                            height: 65px;
                          }
                        `}
                      />
                    }
                    title={<FormattedHTMLMessage id="crowd.title" />}
                    text={<FormattedHTMLMessage id="crowd.text" />}
                  />
                  <FeatureCard
                    icon={
                      <CustomSoftware
                        css={css`
                          height: 65px;
                          margin-bottom: 8px;

                          ${Tablet} {
                            margin-bottom: -10px;
                            height: 80px;
                          }

                          ${Desktop} {
                            margin-bottom: 0px;
                            height: 65px;
                          }
                        `}
                      />
                    }
                    title={<FormattedHTMLMessage id="software.title" />}
                    text={<FormattedHTMLMessage id="software.text" />}
                  />
                </div>
              </FadeInSection>
            </div>
          </Container>
        </div>
        <div id="robots" />

        <RobotSection
          name="rice"
          background="#fafafa"
          index={0}
          image={<Rice fluid={data.rice.childImageSharp.fluid} />}
          sectionStyle={{
            [Mobile]: { marginTop: "80px", marginBottom: "400px" },
            [Tablet]: { marginTop: "100px", marginBottom: "500px" },
            [Desktop]: { marginTop: "120px", marginBottom: "60px" },
          }}
        >
          <RiceFeatures />
        </RobotSection>

        <RobotSection
          name="jasmine"
          background="#eaeaea"
          index={1}
          image={<Jasmine fluid={data.jasmine.childImageSharp.fluid} />}
          sectionStyle={{
            [Mobile]: { marginTop: "80px", marginBottom: "420px" },
            [Tablet]: { marginTop: "100px", marginBottom: "550px" },
            [Desktop]: { marginTop: "120px", marginBottom: "60px" },
          }}
        >
          <JasmineFeatures />
        </RobotSection>

        <RobotSection
          name="portal"
          background="#c7c7c7"
          index={2}
          image={<Portal fluid={data.portal.childImageSharp.fluid} />}
          sectionStyle={{
            [Mobile]: { marginTop: "80px", marginBottom: "540px" },
            [Tablet]: { marginTop: "100px", marginBottom: "500px" },
            [Desktop]: { marginTop: "120px", marginBottom: "60px" },
          }}
        >
          <PortalFeatures />
        </RobotSection>

        <div
          css={css`
            position: relative;
            z-index: 10;
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: #fff;
          `}
        >
          <Container>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 100%;
                background: #fff;
                margin-top: 60px;

                ${Tablet} {
                  margin-top: 120px;
                }
                ${Desktop} {
                  margin-top: 150px;
                }
              `}
            >
              <h1>
                <FormattedHTMLMessage id="core.name" />
              </h1>
              <h2>
                <FormattedHTMLMessage id="core.type" />
              </h2>
              <p
                css={css`
                  padding: 0px;
                  min-width: 280px;
                  max-width: 400px;
                  ${Tablet} {
                    max-width: none;
                    width: auto;
                  }
                  ${Desktop} {
                    padding: 0px 90px;
                  }
                `}
              >
                <FormattedHTMLMessage id="core.text" />
              </p>
              <div
                css={css`
                  min-width: 280px;
                  width: 100%;
                  max-width: 400px;
                  padding-top: 30px;
                  margin-bottom: 40px;
                  user-select: none;
                  ${Tablet} {
                    max-width: none;
                    width: 100%;
                    padding-top: 60px;
                    margin-bottom: 80px;
                  }
                  ${Desktop} {
                    padding-top: 80px;
                    margin-bottom: 90px;
                  }
                `}
              >
                <Img
                  fluid={data.dashboard.childImageSharp.fluid}
                  loading="eager"
                />
              </div>
            </div>

            <BlogList />
          </Container>
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;
